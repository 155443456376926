import { Typography } from '@material-tailwind/react';
import React from 'react';

export interface TagProps {
  text: string;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  // TODO: refactor to use ComponentVariant
  variant?: 'success' | 'error' | 'warning' | 'info' | 'neutral';
  className?: string;
  textClassName?: string;
}

const getTagClasses = (size: 'xs' | 'sm' | 'md' | 'lg' = 'md') => {
  const sizeClasses = {
    xs: 'px-1.5 py-0 text-[0.65rem] leading-4',
    sm: 'px-2 py-0.5 text-xs',
    md: 'px-2 py-1 text-sm',
    lg: 'px-2.5 py-1.5 text-md',
  };
  return sizeClasses[size];
};

const getTextClasses = (
  size: 'xs' | 'sm' | 'md' | 'lg' = 'md',
  textClassName?: string
) => {
  const sizeClasses = {
    xs: 'text-2xs',
    sm: 'text-xs',
    md: 'text-sm',
    lg: 'text-base',
  };
  return `${sizeClasses[size]} ${textClassName || ''}`;
};

const variantClasses = {
  success: 'bg-white text-green-700 ring-green-700/60 hover:bg-green-50/20',
  error: 'bg-white text-red-700 ring-red-700/60 hover:bg-red-50/20',
  warning: 'bg-white text-yellow-700 ring-yellow-700/60 hover:bg-yellow-50/20',
  info: 'bg-white text-blue-700 ring-blue-700/60 hover:bg-blue-50/20',
  neutral: 'bg-white text-gray-700 ring-gray-700/60 hover:bg-gray-50/20',
};

const Tag: React.FC<TagProps> = ({
  text,
  size = 'md',
  variant = 'neutral',
  className,
  textClassName,
}) => (
  <span
    className={`
      inline-flex rounded-full font-medium ring-1 ring-inset transition-colors duration-200
      ${getTagClasses(size)}
      ${variantClasses[variant]}
      ${className || ''}
    `.trim()}
  >
    <Typography className={getTextClasses(size, textClassName)}>
      {text}
    </Typography>
  </span>
);

export default Tag;
