import React, { ReactNode } from 'react';
import Label from '../label';
import Select from '../select';

interface LabelSelectProps {
  label: string;
  options: { content: ReactNode | string; value: string }[];
  variant?: 'primary' | 'secondary';
  size?: 'md' | 'sm' | 'xs';
  className?: string;
  selectProps?: Omit<React.ComponentProps<typeof Select>, 'options'>;
  labelPosition?: 'side' | 'top';
}

const LabelSelect: React.FC<LabelSelectProps> = ({
  label,
  options,
  variant = 'primary',
  size = 'md',
  className = '',
  selectProps = {},
  labelPosition = 'side',
}) => {
  const containerClasses = {
    side: 'flex-col sm:flex-row sm:items-center justify-between',
    top: 'flex-col relative',
  };

  const labelClasses = {
    top: [
      'absolute -top-[0.6rem] left-3 z-10',
      'text-xs text-gray-600 font-medium leading-none',
      'px-0.5',
      'bg-gradient-to-b from-white via-white to-transparent',
      'pb-1',
    ].join(' '),
    side: '',
  };
  const topLabelSize = ['xs', 'sm'].includes(size) ? '2xs' : 'xs';
  const labelSize = labelPosition === 'top' ? topLabelSize : size;

  return (
    <div
      className={`flex ${containerClasses[labelPosition]} ${labelPosition === 'top' ? '' : 'gap-2'} ${className}`}
    >
      <div className={labelPosition === 'side' ? 'sm:w-1/3' : ''}>
        <Label
          variant={variant}
          size={labelSize}
          className={labelClasses[labelPosition]}
        >
          {label}
        </Label>
      </div>
      <div className="flex-1">
        <Select
          options={options}
          variant={variant}
          size={size}
          {...selectProps}
        />
      </div>
    </div>
  );
};

export default LabelSelect;
