import { Conversation } from 'src/api/generated';
import {
  getConversationExpirationInfo,
  formatExpirationTime,
} from 'src/modules/messaging/utils/conversations/conversation.util';
import ConversationStatusDisplay from 'src/modules/messaging/components/atoms/conversations/conversation-status-display';
import Tag from 'src/modules/shared/components/atoms/tag';

interface ConversationExpirationDisplayProps {
  conversation: Conversation;
}

const ConversationExpirationDisplay: React.FC<
  ConversationExpirationDisplayProps
> = ({ conversation }) => {
  const { isExpired, timeRemaining } =
    getConversationExpirationInfo(conversation);

  if (isExpired) {
    return <ConversationStatusDisplay status="expired" size="sm" />;
  }

  if (timeRemaining !== null) {
    return (
      <Tag
        text={formatExpirationTime(timeRemaining)}
        variant="info"
        size="sm"
      />
    );
  }

  return null;
};

export default ConversationExpirationDisplay;
