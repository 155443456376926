import { PlusIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { ConversationStatus } from 'src/api/generated';
import { getConversationStatusOptions } from 'src/modules/messaging/utils/conversations/status.util';
import { WhatsAppTemplateModal } from 'src/modules/messaging/whatsapp/components/organisms/templates/template-modal';
import LabelSelect from 'src/modules/shared/components/atoms/inputs/label-select';
import Button from 'src/modules/shared/components/atoms/buttons/button';

interface ChatActionsHeaderProps {
  selectedStatus: ConversationStatus | undefined;
  onStatusChange: (status?: ConversationStatus) => void;
}

const containerClasses = `
  flex-none flex flex-col lg:flex-row overflow-hidden border-gray-200 
  p-3 items-center justify-between bg-white
  space-y-2 lg:space-y-0 min-h-[4rem] lg:h-16
`;
const actionsContainerClasses =
  'flex items-center space-x-2 w-full lg:w-auto justify-end';

const ChatActionsHeader: React.FC<ChatActionsHeaderProps> = ({
  selectedStatus,
  onStatusChange,
}) => {
  const [isWhatsAppTemplateModalOpen, setIsWhatsAppTemplateModalOpen] =
    React.useState(false);

  const handleStatusChange = React.useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const value = event.target.value as ConversationStatus | '';
      const updatedStatus = value === '' ? undefined : value;

      onStatusChange(updatedStatus);
    },
    [onStatusChange]
  );

  const statusOptions = React.useMemo(
    () =>
      getConversationStatusOptions({
        includeAllOption: true,
      }),
    []
  );

  return (
    <div className={containerClasses}>
      <div className="flex w-full lg:w-auto lg:max-w-64">
        <LabelSelect
          // TODO: check when implementing i18n
          label="Filtrar por estado"
          labelPosition="top"
          options={statusOptions}
          size="sm"
          className="w-full sm:w-auto sm:max-w-64"
          selectProps={{
            value: selectedStatus || '',
            onChange: handleStatusChange,
          }}
        />
      </div>

      <div className={actionsContainerClasses}>
        <Button size="sm" onClick={() => setIsWhatsAppTemplateModalOpen(true)}>
          <PlusIcon className="h-4 w-4 mr-1" />
          {/* TODO: check when implementing i18n */}
          Nueva conversación
        </Button>

        <WhatsAppTemplateModal
          isOpen={isWhatsAppTemplateModalOpen}
          onClose={() => setIsWhatsAppTemplateModalOpen(false)}
        />
      </div>
    </div>
  );
};

export default ChatActionsHeader;
